/* 自助机 */
export const ssm = {
   // 自助机列表 分页
   ssmPaging: '/hotel/hotelSsm/getPage',
   // 添加自助机
   addSsm: '/hotel/hotelSsm/add',
   // 编辑自助机
   editSsm: '/hotel/hotelSsm/update',
   // 删除自助机
   delSsm: '/hotel/hotelSsm/delete',
   // 保存自助机配置
   saveSsmCfg: '/hotel/ssmcfg/saveSsmCfg',
   // 获取自助机配置
   getSsmCfg: '/hotel/ssmcfg/getSsmCfg',
}

/* 能耗管理 */
export const electricity = {
   // 查询能耗列表
   electricity: '/quartz/electricity/query',
   // 创建节省任务
   createElectricity: '/quartz/electricity/create',
   // 修改节省任务
   updateElectricity: '/quartz/electricity/update',
   // 暂停/启动节省任务
   setJobState: '/quartz/electricity/setJobState',
   // 删除能耗任务
   delElectricity: '/quartz/electricity/delete',
   // 获取房间列表
   roomInfoList: '/hotel/room/getRoomInfoList',
   // 获取任务详情
   electricityDetail: '/quartz/electricity/detail',
   // 获取已选中产品/设备
   deviceParams: '/quartz/electricityDevice/getList',
   // 根据房型获取产品列表
   roomTypeProducts: '/device-hotel/device/getDeviceListByRoomType',
}

