<template>
   <div class="cont">
      <!-- 面包屑 -->
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>AIOT</el-breadcrumb-item>
            <el-breadcrumb-item>自助机管理</el-breadcrumb-item>
            <el-breadcrumb-item>自助机列表</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
      <el-row class="content-box">
         <!-- 表头 -->
         <el-row class="search-box">
            <el-row class="search-row">
               <el-row class="search-item">
                  <label>酒店名称：</label>
                  <el-autocomplete
                        class="width-220"
                        v-model="hotelName"
                        clearable
                        :fetch-suggestions="querySearchAsync"
                        :placeholder="$t('msg.hotel_name')"
                        @select="handleSelect"
                  ></el-autocomplete>
               </el-row>
               <el-button class="bg-gradient" type="primary"  @click="handleQuery(true)">
                  <span v-text="$t('msg.search')">搜索</span>
               </el-button>
               <el-button type="primary"  plain @click="handleQuery(false)">
                  <span v-text="$t('msg.reset')">重置</span>
               </el-button>
            </el-row>
         </el-row>
         <!-- 主体内容 -->
         <div class="table-box">
            <div class="table-head-btn">
               <el-row>
                  <el-button-group class="m-right-10">
                     <el-button  @click="handleTab('')" v-text="$t('msg.whole')" >全部</el-button>
                     <el-button  @click="handleTab('ONLINE')" v-text="$t('msg.online_device')">在线设备</el-button>
                     <el-button  @click="handleTab('OFFLINE')" v-text="$t('msg.off_line_device')">离线设备</el-button>
                     <el-button  @click="handleTab('PROHIBIT')" v-text="$t('msg.disable_device')">禁用设备</el-button>
                     <el-button  @click="handleTab('NOT_ACTIVE')" v-text="$t('msg.device_not_activated')">未激活设备</el-button>
                  </el-button-group>
               </el-row>
               <el-row class="right-btn">
                  <el-button class="m-bottom-5 bg-gradient" type="primary"  @click="handleAdd" style="float: right">新增自助机</el-button>
               </el-row>
            </div>
            <el-table
                  v-loading="loading"
                  :data="tableData"
                  style="width: 100%"
                  :stripe="true"
                  border fit
                  >
               <el-table-column prop="number" label="序号" width="60">
                  <template slot-scope="scope">
                     {{scope.$index + 1}}
                  </template>
               </el-table-column>
               <el-table-column prop="serial" :label="$t('msg.serial_number')"></el-table-column>
               <el-table-column prop="macAddress" :label="$t('msg.mac_address')" min-width="70"></el-table-column>
               <el-table-column prop="brand" :label="$t('msg.dev_brand')" min-width="80"></el-table-column>
               <el-table-column :label="$t('msg.status')">
                  <template slot-scope="scope">
                     <span>{{scope.row.ssmStatus | filterState}}</span>
                  </template>
               </el-table-column>
               <el-table-column prop="ipAddress" :label="$t('msg.own_hotel')" min-width="70"></el-table-column>
               <el-table-column prop="position" :label="$t('msg.install_location')" min-width="100"></el-table-column>
               <el-table-column prop="lastOnlineDate" :label="$t('msg.last_online_time')" min-width="90"></el-table-column>
               <el-table-column :label="$t('msg.operating')" min-width="240" sortable fixed="right">
                  <template slot-scope="scope">
                     <el-button type="text" icon="el-icon-edit" @click="handleEdit(scope.row)" >
                        <el-link type="primary" v-text="$t('msg.edit')">编辑</el-link>
                     </el-button>
                     <el-button type="text" style="color: #F56C6C;" icon="el-icon-delete" @click="handleDel(scope.row.id)" >
                        <el-link type="danger" v-text="$t('msg.remove')">删除</el-link>
                     </el-button>
                     <el-button type="text" icon="el-icon-set-up" @click="handleCfg(scope.row.id, scope.row.hotelId)" >
                        <el-link type="primary">配置</el-link>
                     </el-button>
                     <el-dropdown @command="handleCommand" trigger="click" style="margin-left: 10px">
                        <el-button class="el-dropdown-link" icon="el-icon-more-outline" type="text" >
                           <el-link type="primary">状态<i class="el-icon-arrow-down el-icon--right"></i></el-link>
                        </el-button>
                        <el-dropdown-menu slot="dropdown">
                           <el-dropdown-item :command="[scope.row.id, 'PROHIBIT']">禁用</el-dropdown-item>
                           <el-dropdown-item :command="[scope.row.id, 'ONLINE']">启用</el-dropdown-item>
                        </el-dropdown-menu>
                     </el-dropdown>
                  </template>
               </el-table-column>
            </el-table>
            <!-- 分页 -->
            <pagination :total="total" :page-size="limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="handlePaging"/>
         </div>
         <!-- 新增/编辑弹窗 -->
         <el-dialog :title="$t('msg.new_ssm')" :visible.sync="isShow" width="700px" :before-close="handleClose">
            <el-form class="form-dialog-box">
               <el-row class="form-row">
                  <el-form-item required label="所属酒店">
                     <el-select @focus="handleSelectList"  class="width-220" v-model="hotelId" placeholder="请选择">
                        <el-option
                              v-for="item in hotelList"
                              :key="item.id"
                              :label="item.hotelName"
                              :value="item.id">
                        </el-option>
                     </el-select>
                  </el-form-item>
                  <el-form-item label="品牌">
                     <el-input  class="width-220" v-model="brand" :placeholder="$t('msg.dev_brand')" clearable></el-input>
                  </el-form-item>
               </el-row>
               <el-row class="form-row">
                  <el-form-item required label="序列号">
                     <el-input  class="width-220" v-model="serial" :placeholder="$t('msg.serial_number')" clearable></el-input>
                  </el-form-item>
                  <el-form-item label="生产日期" required>
                     <el-date-picker
                           v-model="produceDate"
                           value-format="yyyy-MM-dd HH:mm:ss"
                           type="date"

                           class="width-220"
                           placeholder="选择日期">
                     </el-date-picker>
                  </el-form-item>
               </el-row>
               <el-row class="form-row">
                  <el-form-item required label="操作系统">
                     <el-select  v-model="system" class="width-220" placeholder="请选择">
                        <el-option
                              v-for="item in systemList"
                              :key="item"
                              :label="item"
                              :value="item">
                        </el-option>
                     </el-select>
                  </el-form-item>
                  <el-form-item required label="出厂版本">
                     <el-input  class="width-220" v-model="version" :placeholder="$t('msg.factory_version')" clearable></el-input>
                  </el-form-item>
               </el-row>
               <el-row class="form-row">
                  <el-form-item label="MAC地址">
                     <el-input  class="width-220" v-model="macAddress" :placeholder="$t('msg.mac_address')" clearable></el-input>
                  </el-form-item>
                  <el-form-item></el-form-item>
               </el-row>
               <el-form-item label="安装位置">
                  <el-input  class="width-550" v-model="position" :placeholder="$t('msg.install_location')" clearable></el-input>
               </el-form-item>
            </el-form>

            <span slot="footer" class="dialog-footer">
               <el-button  @click="resetForm()" v-text="$t('msg.reset')">重置</el-button>
               <el-button class="bg-gradient" type="primary" @click="handleSave()" v-text="$t('msg.save')">保存</el-button>
            </span>
         </el-dialog>
         <!-- 配置弹窗 -->
         <el-form label-width="105px">
            <el-dialog title="配置自助机" :visible.sync="isCfgShow" width="760px" :before-close="handleCfgClose">
               <template>
                  <el-tabs v-model="activeName">
                     <el-tab-pane label="基本设置" name="first">
                        <el-row class="m-bottom-15">
                           <el-col :span="12">
                              <el-col :span="9" class="t-right">
                                 <span class="l-height-26">自助机型号：</span>
                              </el-col>
                              <el-col :span="15">
                                 <el-input  class="width-200" v-model="content.base.ssmType" placeholder="型号" clearable></el-input>
                              </el-col>
                           </el-col>
                           <el-col :span="12">
                              <el-col :span="9" class="t-right">
                                 <span class="l-height-26">PMS酒店编码：</span>
                              </el-col>
                              <el-col :span="15" class="t-left">
                                 <el-input  class="width-200" v-model="content.base.pmsId" placeholder="编码" clearable></el-input>
                              </el-col>
                           </el-col>
                        </el-row>
                        <el-row class="m-bottom-15">
                           <el-col :span="12">
                              <el-col :span="9" class="t-right">
                                 <span class="l-height-26">钟点房的客源渠道：</span>
                              </el-col>
                              <el-col :span="15">
                                 <el-input  class="width-200" v-model="content.base.hourComeFrom" placeholder="客源渠道" clearable></el-input>
                              </el-col>
                           </el-col>
                        </el-row>
                        <el-row class="m-bottom-15">
                           <el-col :span="12">
                              <el-col :span="9" class="t-right">
                                 <span class="l-height-26">客源渠道：</span>
                              </el-col>
                              <el-col :span="15">
                                 <el-input  class="width-200" v-model="content.base.comeFrom" placeholder="客源渠道" clearable></el-input>
                              </el-col>
                           </el-col>
                        </el-row>
                     </el-tab-pane>
                     <el-tab-pane label="房卡设置" name="third">
                        <el-row>发卡机设置</el-row>
                        <el-row class="m-bottom-15">
                           <el-col :span="12">
                              <el-col :span="9" class="t-right">
                                 <span class="l-height-26">发卡机型号：</span>
                              </el-col>
                              <el-col :span="15">
                                 <el-input  class="width-200" v-model="content.roomCard.sendCard.type" placeholder="型号" clearable></el-input>
                              </el-col>
                           </el-col>
                           <el-col :span="12">
                              <el-col :span="9" class="t-right">
                                 <span class="l-height-26">发卡机端口：</span>
                              </el-col>
                              <el-col :span="15" class="t-left">
                                 <el-input  class="width-200" v-model="content.roomCard.sendCard.port" placeholder="端口" clearable></el-input>
                              </el-col>
                           </el-col>
                        </el-row>
                        <el-row class="m-bottom-15">
                           <el-col :span="12">
                              <el-col :span="9" class="t-right">
                                 <span class="l-height-26">发卡机波特率：</span>
                              </el-col>
                              <el-col :span="15">
                                 <el-input  class="width-200" v-model="content.roomCard.sendCard.baud" placeholder="波特率" clearable></el-input>
                              </el-col>
                           </el-col>
                           <el-col :span="12">
                              <el-col :span="9" class="t-right">
                                 <span class="l-height-26">发卡机通讯地址：</span>
                              </el-col>
                              <el-col :span="15">
                                 <el-input  class="width-200" v-model="content.roomCard.sendCard.address" placeholder="通讯地址" clearable></el-input>
                              </el-col>
                           </el-col>
                        </el-row>
                        <el-row>门锁读卡器</el-row>
                        <el-row class="m-bottom-15">
                           <el-col :span="12">
                              <el-col :span="9" class="t-right">
                                 <span class="l-height-26">门锁型号：</span>
                              </el-col>
                              <el-col :span="15">
                                 <el-input  class="width-200" v-model="content.roomCard.doorLock.type" placeholder="型号" clearable></el-input>
                              </el-col>
                           </el-col>
                           <el-col :span="12">
                              <el-col :span="9" class="t-right">
                                 <span class="l-height-26">门锁软件：</span>
                              </el-col>
                              <el-col :span="15" class="t-left">
                                 <el-input  class="width-200" v-model="content.roomCard.doorLock.lockCard" placeholder="门锁软件" clearable></el-input>
                              </el-col>
                           </el-col>
                        </el-row>
                        <el-row class="m-bottom-15">
                           <el-col :span="12">
                              <el-col :span="9" class="t-right">
                                 <span class="l-height-26">门锁数据库：</span>
                              </el-col>
                              <el-col :span="15">
                                 <el-input  class="width-200" v-model="content.roomCard.doorLock.dbServer" placeholder="门锁数据库" clearable></el-input>
                              </el-col>
                           </el-col>
                           <el-col :span="12">
                              <el-col :span="9" class="t-right">
                                 <span class="l-height-26">用户名：</span>
                              </el-col>
                              <el-col :span="15" class="t-left">
                                 <el-input  class="width-200" v-model="content.roomCard.doorLock.logUser" placeholder="用户名" clearable></el-input>
                              </el-col>
                           </el-col>
                        </el-row>
                        <el-row class="m-bottom-15">
                           <el-col :span="12">
                              <el-col :span="9" class="t-right">
                                 <span class="l-height-26">数据库类型：</span>
                              </el-col>
                              <el-col :span="15">
                                 <el-input  class="width-200" v-model="content.roomCard.doorLock.dbFlag" placeholder="数据库类型" clearable></el-input>
                              </el-col>
                           </el-col>
                        </el-row>
                     </el-tab-pane>
                     <el-tab-pane label="公安设置" name="fourth">
                        <el-row>人脸识别</el-row>
                        <el-row class="m-bottom-15">
                           <el-col :span="12">
                              <el-col :span="9" class="t-right">
                                 <span class="l-height-26">产品型号：</span>
                              </el-col>
                              <el-col :span="15">
                                 <el-input  class="width-200" v-model="content.police.face.type" placeholder="型号" clearable></el-input>
                              </el-col>
                           </el-col>
                           <el-col :span="12">
                              <el-col :span="9" class="t-right">
                                 <span class="l-height-26">APP_ID：</span>
                              </el-col>
                              <el-col :span="15" class="t-left">
                                 <el-input  class="width-200" v-model="content.police.face.faceAppId" placeholder="APP_ID" clearable></el-input>
                              </el-col>
                           </el-col>
                        </el-row>
                        <el-row class="m-bottom-15">
                           <el-col :span="12">
                              <el-col :span="9" class="t-right">
                                 <span class="l-height-26">APP_KEY：</span>
                              </el-col>
                              <el-col :span="15">
                                 <el-input  class="width-200" v-model="content.police.face.faceAppKey" placeholder="APP_KEY" clearable></el-input>
                              </el-col>
                           </el-col>
                           <el-col :span="12">
                              <el-col :span="9" class="t-right">
                                 <span class="l-height-26">SECRET_KEY：</span>
                              </el-col>
                              <el-col :span="15" class="t-left">
                                 <el-input  class="width-200" v-model="content.police.face.faceSecretKey" placeholder="SECRET_KEY" clearable></el-input>
                              </el-col>
                           </el-col>
                        </el-row>
                        <el-row class="m-bottom-15">
                           <el-col :span="12">
                              <el-col :span="9" class="t-right">
                                 <span class="l-height-26">匹配度：</span>
                              </el-col>
                              <el-col :span="15">
                                 <el-input  class="width-200" v-model="content.police.face.faceRate" placeholder="匹配度" clearable></el-input>
                              </el-col>
                           </el-col>
                        </el-row>
                        <el-row>身份证识别</el-row>
                        <el-row class="m-bottom-15">
                           <el-col :span="12">
                              <el-col :span="9" class="t-right">
                                 <span class="l-height-26">产品型号：</span>
                              </el-col>
                              <el-col :span="15">
                                 <el-input  class="width-200" v-model="content.police.idCard.type" placeholder="型号" clearable></el-input>
                              </el-col>
                           </el-col>
                           <el-col :span="12">
                              <el-col :span="9" class="t-right">
                                 <span class="l-height-26">端口：</span>
                              </el-col>
                              <el-col :span="15" class="t-left">
                                 <el-input  class="width-200" v-model="content.police.idCard.port" placeholder="端口" clearable></el-input>
                              </el-col>
                           </el-col>
                        </el-row>
                     </el-tab-pane>
                     <el-tab-pane label="打印设置" name="second">
                        <el-row class="m-bottom-15">
                           <el-col :span="12">
                              <el-col :span="9" class="t-right">
                                 <span class="l-height-26">打印机型号：</span>
                              </el-col>
                              <el-col :span="15">
                                 <el-input  class="width-200" v-model="content.print.type" placeholder="型号" clearable></el-input>
                              </el-col>
                           </el-col>
                           <el-col :span="12">
                              <el-col :span="9" class="t-right">
                                 <span class="l-height-26">波特率：</span>
                              </el-col>
                              <el-col :span="15" class="t-left">
                                 <el-input  class="width-200" v-model="content.print.baud" placeholder="波特率" clearable></el-input>
                              </el-col>
                           </el-col>
                        </el-row>
                        <el-row class="m-bottom-15">
                           <el-col :span="12">
                              <el-col :span="9" class="t-right">
                                 <span class="l-height-26">端口：</span>
                              </el-col>
                              <el-col :span="15">
                                 <el-input  class="width-200" v-model="content.print.port" placeholder="端口" clearable></el-input>
                              </el-col>
                           </el-col>
                        </el-row>
                     </el-tab-pane>
                  </el-tabs>
               </template>
               <span slot="footer" class="dialog-footer">
                  <el-button  @click="resetCfg()" v-text="$t('msg.reset')">重置</el-button>
                  <el-button class="bg-gradient" type="primary" @click="saveConfig" v-text="$t('msg.save')">保存</el-button>
               </span>
            </el-dialog>
         </el-form>
      </el-row>
   </div>
</template>

<script>
   import { urlObj } from '@/api/interface'
   import { ssm } from "@/api/interface/aiot";
   import common from '@/common/js/common'
   import { mapState } from 'vuex'
   export default {
      computed:{
         ...mapState(['hotelInfo'])
      },
      watch:{
         hotelInfo:{
            handler(newVal,oldVal){
               if(newVal.id !== oldVal.id && oldVal){
                  this.hotelIdQ = this.hotelId = this.hotelInfo.id
                  this.getSsm()
               }
            }
         }
      },
      data(){
         return{
            tableData: [],            // 表格列表
            loading: true,            // 主体表格开启加载效果
            isShow: false,            // 新增弹窗显示
            isCfgShow: false,         // 配置弹窗显示
            total: 0,                 // 总条目数
            action: 'add',            // 操作类型
            hotelName: '',            // 酒店名称
            hotelList: [],            // 酒店列表
            systemList: [             // 系统列表
               'windows',
               'linux'
            ],
            activeName: 'first',      // 当前tab项
            /* 列表查询参数 */
            page: 1,                  // 当前页 默认第1页
            limit: 0,                 // 每页显示数
            hotelIdQ: '',             // 酒店ID
            ssmStatus: '',            // 设备状态
            /* 新增自助机表单字段 */
            hotelId: '',              // 酒店ID
            brand: '',                // 品牌
            serial: '',               // 序列号
            produceDate: '',          // 生产日期
            system: '',               // 操作系统
            version: '',              // 出厂版本
            macAddress: '',           // MAC地址
            position: '',             // 安装位置
            /* 自助机配置表单字段 */
            ssmId: '',                // 自助机ID
            id: '',                   // 修改用
            typeName: '',             // 类型名
            typeCode: '',             // 类型编码
            leaf: 0,
            content: {                // 配置项
               base: {                // 基本设置
                  ssmType: 0,         // 自助机型号
                  pmsId: 0,           // PMS酒店编码
                  hourComeFrom: 0,    // 钟点房客源渠道
                  comeFrom: 0         // 客源渠道
               },
               roomCard: {            // 房卡设置
                  sendCard: {         // 发动机设置
                     type: 0,         // 发动机型号
                     port: 0,         // 发动机端口
                     baud: 0,         // 发动机波特率
                     address: 0,      // 通讯地址
                  },
                  doorLock: {         // 门锁读卡器
                     type: 0,         // 门锁型号
                     lockCard: 0,     // 门锁软件
                     dbServer: '',    // 门锁数据库
                     logUser: '',     // 用户名
                     dbFlag: 0,       // 数据库类型
                  }
               },
               police: {               // 公安设置
                  face: {              // 人脸识别
                     type: 0,          // 产品型号
                     faceAppId: 0,     // APP_ID
                     faceAppKey: 0,    // APP_KEY
                     faceSecretKey: 0, // SECRET_KEY
                     faceRate: 0,      // 匹配度
                  },
                  idCard: {            // 身份证识别
                     type: 0,          // 产品型号
                     port: 0           // 产品端口
                  }
               },
               print: {                // 打印设置
                  type: 0,             // 型号
                  baud: 0,             // 波特率
                  port: 0,             // 端口
               },
            },
            /* 弹窗提示文本 */
            name_no_null: "组织名称不能为空！",
            number_no_null: "组织编码不能为空！",
            save_cfg_success: "保存配置成功！",
            del_success: "删除成功！",
            add_success: '添加成功！',
            update_success: '编辑成功！',
            update_state_success: '修改状态成功！',
            enable_success: '启用成功！',
            confirm: '确定',
            cancel: '取消',
            confirm_remove: '确定移除？',
            prompt: '提示！'
         }
      },
      mounted() {
         sessionStorage.setItem('content', JSON.stringify(this.content))
         this.limit = sessionStorage.getItem('pageSize') * 1
         this.getSsm()
      },
      beforeDestroy() {
         sessionStorage.removeItem('content')
      },
      methods: {
         // 获取自助机列表
         getSsm(){
            const param = {
               page: this.page,
               limit: this.limit,
               hotelId: this.hotelIdQ,
               ssmStatus: this.ssmStatus
            }
            const url = ssm.ssmPaging
            this.$axios.post(url, param).then((res) => {
               if(res.success){
                  this.total = res.total
                  this.loading = false
                  this.tableData = res.records
               }
            }).catch(error => {
               console.log(error)
            })
         },
         // 获取酒店列表
         getHotel(){
            return new Promise((resolve, reject) => {
               const url = urlObj.getHotelList
               let promise = this.$axios.post(url, {}, 'json')
               promise.then(res => {
                  resolve(res)
               }).catch(error => {
                  reject(error)
               })
            })
         },
         // 处理查询提示列表
         async querySearchAsync(val, cb) {
            let res = await this.getHotel()
            if (res.success){
               let dataList = res.records
               let dataArr = []
               if (dataList.length > 0){
                  dataList.forEach(item => {
                     dataArr.push({value: item.hotelName,id: item.id})
                  })
                  cb(dataArr)
                  return
               }
               dataArr.push({value: '搜索无结果'})
            }
         },
         // 选中酒店搜索
         handleSelect(item){
            this.hotelIdQ = item.id
            this.hotelName = item.value
            this.getSsm()
         },
         // 重置与搜索
         handleQuery(bool){
            if (bool) return this.getSsm()
            this.hotelName = ''
            this.hotelIdQ = ''
            this.hotelName = ''
            // this.getSsm()
         },
         // 设备类型栏
         handleTab(type){
            this.ssmStatus = type
            this.getSsm()
         },
         // 改变显示条数
         pageChange(v){
            this.limit = v
            this.getSsm()
         },
         // 改变当前页
         handlePaging(v){
            this.page = v
            this.getSsm()
         },
         // 处理酒店下拉列表
         async handleSelectList(){
            let res = await this.getHotel()
            if (res.success){
               const hotelList = res.records
               this.hotelList = hotelList.map(item => {
                  return {id: item.id, hotelName: item.hotelName}
               })
            }
         },
         // 添加自助机
         handleAdd(){
            this.isShow = true
            this.action = 'add'
         },
         // 编辑自助机
         handleEdit(row){
            // 开启弹窗
            this.isShow = true
            // 设置弹窗表单值
            this.hotelId = row.hotelId
            this.brand = row.brand
            this.serial = row.serial
            this.produceDate = row.produceDate
            this.system = row.system
            this.version = row.version
            this.macAddress = row.macAddress
            this.position = row.position
            this.action = 'edit'
            this.id = row.id
         },
         // 重置表单
         resetForm(){
            this.hotelId = ''
            this.brand = ''
            this.serial = ''
            this.produceDate = ''
            this.system = ''
            this.version = ''
            this.macAddress = ''
            this.position = ''
         },
         // 保存操作
         handleSave(){
            let url = ssm.addSsm
            let param = {
               hotelId: this.hotelId,
               brand: this.brand,
               serial: this.serial,
               produceDate: this.produceDate,
               system: this.system,
               version: this.version,
               macAddress: this.macAddress,
               position: this.position
            }
            if (this.action === 'edit'){
               url = ssm.editSsm
               param.id = this.id
            }
            this.$axios.post(url, param, 'json').then(res => {
               if (res.success){
                  this.$message({
                     message: this.action === 'add' ? this.add_success : this.update_success,
                     type: 'success'
                  })
                  this.handleClose()
                  this.getSsm()
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 关闭添加弹窗
         handleClose(){
            this.isShow = false
            this.resetForm()
         },
         // 修改状态
         handleCommand(command){
            const param = {
               id: command[0],
               ssmStatus: command[1]
            }
            const url = ssm.editSsm
            this.$axios.post(url, param, 'json').then(res => {
               if (res.success){
                  this.$message({
                     message: this.update_state_success,
                     type: 'success'
                  })
                  this.getSsm()
               }
            }).catch(error => {
               console.log(error)
            })
         },
         // 删除自助机
         handleDel(id){
            this.$confirm(this.confirm_remove, this.prompt, {
               confirmButtonText: this.confirm,
               cancelButtonText: this.cancel,
               type: 'warning'
            }).then(() => {
               const url = ssm.delSsm + `/${id}`
               this.$axios.get(url, {}).then((res) => {
                  if (res.success){
                     this.$message({
                        message: this.del_success,
                        type: 'success'
                     })
                     this.getSsm()
                  }
               }).catch((error) => {
                  this.$message.error(error)
               })
            })
         },
         // 配置自助机
         handleCfg(ssmId, hotelId){
            this.ssmId = ssmId
            this.hotelId = hotelId
            this.isCfgShow = true
            this.echoSsmCfg()
         },
         // 回显配置
         echoSsmCfg(){
            const url = ssm.getSsmCfg
            const param = {
               ssmId: this.ssmId
            }
            this.$axios.post(url, param).then(res => {
               if (res.success){
                  if (!res.hasOwnProperty('records')) return
                  this.content = JSON.parse(res.records.content)
                  this.id = res.records.id
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 重置配置
         resetCfg(){
            this.content = JSON.parse(sessionStorage.getItem('content'))
         },
         // 保存配置
         saveConfig(){
            const url = ssm.saveSsmCfg
            let param = {
               ssmId: this.ssmId,
               typeName: this.typeName,
               typeCode: this.typeCode,
               leaf: this.leaf,
               content: this.content
            }
            if (this.id) param.id = this.id
            param.content.base.hotelId = this.hotelId
            param.content = JSON.stringify(param.content)
            this.$axios.post(url, param, 'json').then(res => {
               if (res.success){
                  this.$message({
                     message: this.save_cfg_success,
                     type: 'success'
                  })
                  this.handleCfgClose()
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 关闭配置弹窗
         handleCfgClose(){
            this.ssmId = ''
            this.hotelId = ''
            this.id = ''
            this.isCfgShow = false
            this.resetCfg()
         }
      },
      filters: {
         filterState(val){
            switch (val) {
               case 'ONLINE':
                  return val = '在线'
               case 'OFFLINE':
                  return val = '离线'
               case 'PROHIBIT':
                  return val = '禁用'
               case 'NOT_ACTIVE':
                  return val = '未激活'

            }
         }
      }
   }
</script>

<style scoped></style>
